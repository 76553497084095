<template>
  <div></div>
</template>

<script>
export default {
  name: 'Blank',
  created() {
    
  }
}
</script>